<template>
  <div id="page-input-pemeriksaan-lab">
    <b-container fluid>
      <b-row>
        <b-col cols="6" md="6" lg="4" xl="4">
          <b-card>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table-simple borderless small style="margin-bottom: 0;" class="table-info-pasien">
                  <b-tbody>
                    <!-- <b-tr>
                      <b-td style="width:100px">ID</b-td>
                      <b-td style="width:15px">:</b-td>
                      <b-td>-</b-td>
                    </b-tr> -->

                    <b-tr>
                      <b-td>No. Antrean</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.no_antrian }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>Tanggal</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ $moment(data_pasien.tanggal_antrian).format("DD-MM-YYYY") }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>ID Pendaftaran</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.no_pendaftaran }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>Penanggung Jawab</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pemeriksaan.nama_penanggung_jawab }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>Pemeriksa</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pemeriksaan.nama_pemeriksa }}</b-td>
                    </b-tr>


                    <b-tr>
                      <b-td>No. eRM</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.no_rm }}</b-td>
                    </b-tr>


                    <b-tr>
                      <b-td>NIK</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.nik }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>Nama Pasien</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.nama_lengkap }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>Tempat, Tgl Lahir</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.ttl }}</b-td>
                    </b-tr>

                    <!-- <b-tr>
                      <b-td>Umur</b-td>
                      <b-td>:</b-td>
                      <b-td>-</b-td>
                    </b-tr> -->

                    <b-tr>
                      <b-td>Jenis Kelamin</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.jenis_kelamin }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>Alamat</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.alamat_sekarang }}</b-td>
                    </b-tr>

                    <!-- <b-tr>
                      <b-td>Asuransi</b-td>
                      <b-td>:</b-td>
                      <b-td>-</b-td>
                    </b-tr> -->

                    <b-tr>
                      <b-td>Dibuat pada / oleh</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.dibuat_oleh }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-td>Tanggal Selesai</b-td>
                      <b-td>:</b-td>
                      <b-td>{{ data_pasien.tgl_selesai }}</b-td>
                    </b-tr>

                    <!-- <b-tr>
                      <b-td>IDibuat pada / oleh</b-td>
                      <b-td>:</b-td>
                      <b-td>-</b-td>
                    </b-tr> -->
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col cols="6" md="6" lg="8" xl="8">
          <b-card>
            <b-row>
              <b-col cols="12" md="6" lg="6">
                <b-form-group>
                <template v-slot:label>
                  Penanggung Jawab <span class="text-danger">*</span>
                </template>
                <Multiselect
                  id="penanggung_jawab"
                  :state="checkIfValid('penanggung_jawab')"
                  v-model="$v.is_data_sampel.penanggung_jawab.$model"
                  :options="list_tenaga_medis"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="nama_tenaga_medis"
                  track-by="tenaga_medis_id"
                  placeholder="-- Pilih Penanggung Jawab --"
                  size="sm"
                  @input="update_penanggung(is_data_sampel.penanggung_jawab)"
                ></Multiselect>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" lg="6">
                <b-form-group>
                <template v-slot:label>
                  Pemeriksa <span class="text-danger">*</span>
                </template>
                <Multiselect
                  id="pemeriksa"
                  :state="checkIfValid('pemeriksa')"
                  v-model="$v.is_data_sampel.pemeriksa.$model"
                  :options="list_tenaga_medis"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="nama_tenaga_medis"
                  track-by="tenaga_medis_id"
                  placeholder="-- Pilih Pemeriksa --"
                  size="sm"
                  @input="update_pemeriksa(is_data_sampel.pemeriksa)"
                ></Multiselect>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="6" lg="4">
                <b-form-group
                  label="Dokter Perujuk"
                >
                  <b-form-input
                    type="text"
                    v-model="data_pemeriksaan.nama_perujuk"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" lg="4">
                <b-form-group
                  label="Ruangan Asal"
                >
                  <b-form-input
                    type="text"
                    v-model="data_pemeriksaan.rujukan_dari"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" lg="4">
                <b-form-group
                  label="Tarif Pendaftaran"
                >
                  <b-form-input
                    type="text"
                    v-model="data_pemeriksaan.tarif_pemeriksaan_lab"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr/>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <h6 class="title-16">Pengambilan Sampel</h6>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="8" sm="8" md="8" lg="8">
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Jenis Sampel <span class="text-danger">*</span>
                  </template>
                  <Multiselect
                    id="jenis_sampel"
                    :state="checkIfValid('jenis_sampel')"
                    v-model="$v.is_data_sampel.jenis_sampel.$model"
                    :options="list_sampel"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="nama_sampel"
                    track-by="ms_sampel_lab_id"
                    placeholder="-- Pilih Sampel --"
                    size="sm"
                  ></Multiselect>
                </b-form-group>

                
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Satuan Takaran <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    type="text"
                    :state="checkIfValid('satuan_takaran')"
                    v-model="$v.is_data_sampel.satuan_takaran.$model"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Jumlah <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    type="text"
                    :state="checkIfValid('jumlah')"
                    v-model="$v.is_data_sampel.jumlah.$model"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Batas Waktu Sampel<span class="text-danger">*</span>
                  </template>
                  <DatePicker
                    style="width: 100%"
                    v-model="$v.is_data_sampel.batas_waktu_sampel.$model"
                    :state="checkIfValid('batas_waktu_sampel')"
                    type="date"
                    format="YYYY-MM-DD"
                    placeholder="Batas Waktu Sampel"
                  ></DatePicker>
                </b-form-group>

                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Catatan
                  </template>
                  <b-form-textarea
                    id="textarea"
                    rows="2"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="3" lg="3"></b-col>
              <b-col cols="12" sm="12" md="9" lg="9">
                <div style="width: 100%;display: flex;justify-content: flex-start;">
                  <b-button :disabled="!isValid" @click="addSampel()" variant="success" class="mr-2">Tambahkan</b-button>
                  <b-button variant="warning" class="mr-2">Reset</b-button>
                </div>
              </b-col>
            </b-row>
            
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr/>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12">
                <b-table-simple hover striped style="margin-bottom: 0;" class="table-pengambilan-sampel">
                  <b-thead>
                    <b-tr>
                      <b-th>Tanggal Pengambilan</b-th>
                      <b-th>Batas Waktu</b-th>
                      <b-th>Jenis Sampel</b-th>
                      <b-th>Satuan Takaran</b-th>
                      <b-th>Jumlah</b-th>
                      <b-th class="table-option-3">Catatan</b-th>
                      <b-th>Aksi</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(item, idx) in data_sampel" :key="idx">
                      <b-td>{{ $moment(item.tanggal_sampel).format("DD-MM-YYYY HH:mm") }}</b-td>
                      <b-td>{{ $moment(item.batas_waktu_sampel).format("DD-MM-YYYY") }}</b-td>
                      <b-td>{{ item.nama_sampel }}</b-td>
                      <b-td>{{ item.satuan_takaran }}</b-td>
                      <b-td>{{ item.jumlah }}</b-td>
                      <b-td>{{ item.catatan_sampel_lab }}</b-td>
                      <b-td>
                        <div style="display:flex;justify-content:center">
                          <b-button @click="deleteSampel(item)" variant="warning" class="mr-2"><CIcon name="cil-trash" /></b-button>
                        </div>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr/>
              </b-col>
            </b-row>

            <b-row style="margin-top:15px;">
              <b-col cols="12" md="12" lg="12">
                <b-table-simple hover striped style="margin-bottom: 0;" class="table-pengambilan-sampel">
                  <b-thead>
                    <b-tr>
                      <b-th>Pemeriksaan</b-th>
                      <b-th>Tarif</b-th>
                      <b-th>Nilai</b-th>
                      <b-th>Hasil Pemeriksaan</b-th>
                      <b-th>Satuan</b-th>
                      <b-th>Sample</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(item, idx) in list_pemeriksaan" :key="idx">
                      <b-td><b>{{ item.nama_sub_pemeriksaan_lab }}</b></b-td>
                      <b-td >{{ item.harga_sub_pemeriksaan_lab }}</b-td>
                      <b-td v-if="!item.sub_nilai_lab.length" style="width: 20%">
                        <b-form-input
                          type="text"
                          v-model="item.hasil_pemeriksaan"
                          @change="getNilai(item, idx)"
                        ></b-form-input>
                      </b-td>
                      <b-td v-else style="width: 20%">
                        <b-form-select
                          type="text"
                          v-model="item.hasil_pemeriksaan_text"
                          :options="item.sub_nilai_lab"
                          @change="getNilaiText(item, idx)"
                        ></b-form-select>
                      </b-td>
                      <b-td style="width: 20%">
                        <b-form-input
                          type="text"
                          v-model="item.nilai_pemeriksaan"
                        ></b-form-input>
                      </b-td>
                      <b-td style="width: 15%">
                        <b-form-input
                          type="text"
                          v-model="item.satuan_pemeriksaan"
                          disabled
                        ></b-form-input>
                      </b-td>
                      <b-td>
                        <b-form-select
                          :options="options_sampel"
                          v-model="item.sampel_lab_id"
                        ></b-form-select>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>        
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr/>
              </b-col>
            </b-row>

            <!-- <b-row>
              <b-col cols="6" md="6" lg="6">
                <h6 class="title-16">Kesimpulan / Saran</h6>
                <label for="">Status Pemeriksaan</label>
                <multiselect
                  id=""
                  :options="option_pemeriksaan"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="text"
                  track-by="value"
                  placeholder="-- Pilih --"
                  size="sm"
                ></multiselect>

                <div style="width:100%;display:flex;border:1px solid #e4e7ea;border-radius:0.25rem" class="mt-1" mb-2>
                  <div style="width:75%;height:50px;background-color:;display:flex;justify-content:flex-start;align-items:center;padding-left:15px;">
                    <label for="" class="mb-0">Hasil</label>
                  </div>
                  <div style="width:25%;height:50px;background-color:;display:flex;justify-content:flex-end;align-items:center;padding-right:15px;">
                    <label for="" class="mb-0">-</label>
                  </div>
                </div>
                <label for="" class="mt-2">Saran</label>
                <b-form-textarea
                  id="textarea"
                  rows="3"
                ></b-form-textarea>
              </b-col>
              <b-col cols="6" md="6" lg="6">
                <h6 class="title-16">Rujuk Laboratorium Lain ?</h6>
                <label for="">Puskesmas</label>
                <multiselect
                  id=""
                  :options="option_puskesmas"
                  :multiple="false"
                  :searchable="true"
                  :close-on-select="true"
                  :show-labels="false"
                  label="text"
                  track-by="value"
                  placeholder="-- Pilih --"
                  size="sm"
                ></multiselect>
              </b-col>
            </b-row> -->

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr/>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <div style="width: 100%;display: flex;justify-content: flex-end;">
                  <b-button :disabled="!is_data_sampel.penanggung_jawab || !is_data_sampel.pemeriksa" @click="update_pemeriksaan()" variant="success" class="mr-2">Simpan</b-button>
                  <!-- <b-button variant="primary" class="mr-2">Cetak Blanko Pemeriksaan</b-button> -->
                  <!-- <b-button variant="info" class="mr-2">Cetak Hasil Pemeriksaan</b-button> -->
                  <b-button variant="warning" class="mr-2">Reset</b-button>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import _ from "lodash"
export default {
  name: "input_paket",
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      data_pasien: {
        no_antrian: null,
      },
      options_sampel: [
        {value: null, text: 'Pilih'}
      ],
      is_data_sampel: {
        penanggung_jawab_id: null,
        pemeriksa_id:null,
        penanggung_jawab: null,
        pemeriksa:null,
        ms_sampel_lab_id: null,
        pemeriksaan_lab_id: null,
        tanggal_sampel: this.$moment(new Date()).format("YYYY-MM-DD HH:mm"),
        batas_waktu_sampel: null,
        satuan_takaran: null,
        jumlah: null,
        catatan_sampel_lab: null,
      },
      list_pemeriksaan: [],
      list_tenaga_medis: [],
      list_sampel: [],
      data_pemeriksaan: "",
      data_sampel: []
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data_sampel, null, 4);
    },
    isValid() {
      return !this.$v.is_data_sampel.$invalid;
    },
    isDirty() {
      return this.$v.is_data_sampel.$anyDirty;
    },
    id_kunjungan_lab() {
      return this.$route.query ? this.$route.query.id_kunjungan_lab : null;
    },
    id_rm() {
      return this.$route.query ? this.$route.query.id_rm : null;
    },
    id_kunjungan() {
      return this.$route.query ? this.$route.query.id_kunjungan : null;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data_sampel: {
      penanggung_jawab: { required },
      pemeriksa: { required },
      batas_waktu_sampel: { required },
      jenis_sampel: { required },
      satuan_takaran: { required },
      jumlah: { required },
    },
  },
  activated() {
    // Set the initial number of items
    this.getData()
    this.getSampel()
  },
  methods: {
    update_penanggung(x){
      let vm = this
      vm.$axios.post("pemeriksaan_lab/update", {
        id: vm.data_pemeriksaan.pemeriksaan_lab_id,
        penanggung_jawab_id: x.tenaga_medis_id
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
    },
    update_pemeriksa(x){
      let vm = this
      vm.$axios.post("pemeriksaan_lab/update", {
        id: vm.data_pemeriksaan.pemeriksaan_lab_id,
        pemeriksa_id: x.tenaga_medis_id
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getNilai(data){
      // console.log(x, 'ini x');
      let vm = this
      let jenis_kelamin = ""
      if(vm.data_pasien.jenis_kelamin == "P"){
        jenis_kelamin = "female"
      }else{
        jenis_kelamin = "male"
      }
      vm.$axios.post("/ms_nilai_lab/cek_nilai_lab", {
        sub_tindakan_lab_id : data.sub_tindakan_lab_id,
        umur_tahun: vm.data_pasien.usia_tahun,
        umur_bulan: vm.data_pasien.usia_bulan,
        umur_hari: vm.data_pasien.usia_hari,
        jenis_kelamin: jenis_kelamin,
        hasil_pemeriksaan: data.hasil_pemeriksaan
      })
      .then((res) => {
        console.log(res);
        if(res.data.data.length){
          data.nilai_pemeriksaan = res.data.data[0].nilai_lab
        }
        // console.log(data, 'ini data');
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getNilaiText(data){
      // console.log(data, index, 'ini data, index text');
      let vm = this
      let jenis_kelamin = ""
      if(vm.data_pasien.jenis_kelamin == "P"){
        jenis_kelamin = "female"
      }else{
        jenis_kelamin = "male"
      }
      vm.$axios.post("/ms_nilai_lab/cek_nilai_lab", {
        sub_tindakan_lab_id : data.sub_tindakan_lab_id,
        umur_tahun: vm.data_pasien.usia_tahun,
        umur_bulan: vm.data_pasien.usia_bulan,
        umur_hari: vm.data_pasien.usia_hari,
        jenis_kelamin: jenis_kelamin,
        hasil_pemeriksaan_text: data.hasil_pemeriksaan_text
      })
      .then((res) => {
        console.log(res);
        if(res.data.data.length){
          data.nilai_pemeriksaan = res.data.data[0].nilai_lab
        }
        // console.log(data, 'ini data');
      })
      .catch((err) => {
        console.log(err);
      })
    },
    checkIfValid(fieldName) {
      const field = this.$v.is_data_sampel[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async getData(){
      let vm = this
      let data_pasien = await vm.$axios("kunjungan/details_by_id/"+ vm.id_kunjungan)
      // console.log(data_pasien, 'ini data pasien');
      if(data_pasien.data.data.length){
        vm.data_pasien = data_pasien.data.data[0]
        vm.data_pasien.no_antrian = `${vm.data_pasien.initial}${vm.data_pasien.nomor_antrian}`
        vm.data_pasien.ttl = `${vm.data_pasien.tempat_lahir}, ${vm.$moment(vm.data_pasien.tanggal_lahir).format("DD-MM-YYYY")}`
        
      }
      let kunjungan_lab = await vm.$axios("pemeriksaan_lab/details_by_kunjungan_lab_id/"+ vm.id_kunjungan_lab)
      // console.log(kunjungan_lab, 'ini data kunjungan lab');
      vm.data_pemeriksaan = kunjungan_lab.data.data[0]
      vm.list_pemeriksaan = kunjungan_lab.data.data[0].sub_pemeriksaan_lab
      
      let tenaga_medis = await vm.$axios.post('/tenaga_medis/list')
      vm.list_tenaga_medis = tenaga_medis.data.status == 200 ? tenaga_medis.data.data : []

      vm.is_data_sampel.penanggung_jawab = _.find(vm.list_tenaga_medis, {tenaga_medis_id: vm.data_pemeriksaan.penanggung_jawab_id});
      vm.is_data_sampel.pemeriksa = _.find(vm.list_tenaga_medis, {tenaga_medis_id: vm.data_pemeriksaan.pemeriksa_id});
      if(vm.data_pemeriksaan.tanggal_selesai_pemeriksaan_lab){
          vm.data_pasien.tgl_selesai = vm.$moment(vm.data_pemeriksaan.tanggal_selesai_pemeriksaan_lab).format("DD-MM-YYYY HH:mm")
        }else {
          vm.data_pasien.tgl_selesai = '-'
        }

      let res1 = await vm.$axios.post("/ms_sampel_lab/list");
      vm.list_sampel = res1.data.status == 200 ? res1.data.data : []
      // console.log(res1, 'ini sampel');
      vm.$forceUpdate()
    },
    async getSampel(){
      let vm = this
      let kunjungan_lab = await vm.$axios("pemeriksaan_lab/details_by_kunjungan_lab_id/"+ vm.id_kunjungan_lab)
      // console.log(kunjungan_lab, 'ini data kunjungan lab');
      if(kunjungan_lab.data.data[0].sample_lab.length){
        vm.options_sampel = []
        vm.data_sampel = kunjungan_lab.data.data[0].sample_lab
        for (let i = 0; i < vm.data_sampel.length; i++) {
          let x = vm.data_sampel[i];
          let tampung = {}
          tampung.value = x.sampel_lab_id
          tampung.text = x.nama_sampel
          vm.options_sampel.push(tampung)
        }
      }
      // console.log(vm.options_sampel, 'ini options');
      vm.$forceUpdate()
    },
    addSampel(){
      let vm = this
      // console.log(vm.is_data_sampel, 'ini data sampel');
      vm.$axios.post("/sampel_lab/register", {
        ms_sampel_lab_id: vm.is_data_sampel.jenis_sampel.ms_sampel_lab_id,
        tanggal_sampel: vm.is_data_sampel.tanggal_sampel,
        batas_waktu_sampel: vm.$moment(vm.is_data_sampel.batas_waktu_sampel).format("YYYY-MM-DD"),
        satuan_takaran: vm.is_data_sampel.satuan_takaran,
        jumlah: vm.is_data_sampel.jumlah,
        catatan_sampel_lab: vm.is_data_sampel.catatan_sampel_lab,
        pemeriksaan_lab_id: vm.data_pemeriksaan.pemeriksaan_lab_id,
        pemeriksa_id: vm.is_data_sampel.pemeriksa.tenaga_medis_id,
        penanggung_jawab_id: vm.is_data_sampel.penanggung_jawab.tenaga_medis_id
      })
      .then((res) => {
        console.log(res);
        if(res.data.status == 200 && res.data.message == "sukses"){
          vm.$store.commit('set_alert', {variant: "success", msg: "BERHASIL MENAMBAHKAN SAMPEL", showing: true,})
          vm.getSampel()
        }
      })
      .catch((err) => {
        console.log(err);
      })
    },
    update_pemeriksaan(){
      let vm = this
      // console.log(vm.list_pemeriksaan, 'ini data akhir');
      // for (let i = 0; i < vm.list_pemeriksaan.length; i++) {
      //   let x = vm.list_pemeriksaan[i];
      //   x.id = x.s
      // }
      let tgl = vm.$moment(new Date()).format("YYYY-MM-DD HH:mm")
      vm.$axios.post("/pemeriksaan_lab/update_bulk", {
        sub_pemeriksaan_lab: vm.list_pemeriksaan,
        tanggal_selesai_pemeriksaan_lab: tgl,
        pemeriksaan_lab_id: vm.data_pemeriksaan.pemeriksaan_lab_id
      })
      .then((res) => {
        console.log(res);
        if(res.data.status == 200 && res.data.message == "sukses"){
          vm.$store.commit('set_alert', {variant: "success", msg: "BERHASIL MENYIMPAN DATA PEMERIKSAAN LAB", showing: true,})
          vm.getData()
        }else {
          vm.$store.commit('set_alert', {variant: "danger", msg: res.data.message, showing: true,})
        }
      })
      .catch((err) => {
        console.log(err);
        vm.$store.commit('set_alert', {variant: "danger", msg: "TERJADI KESALAHAN PADA SERVER", showing: true,})
      })
    },
    deleteSampel(x){
      let vm = this
      // console.log(x, 'ini x');
      vm.$axios.post("/sampel_lab/delete", {
        id: x.sampel_lab_id
      })
      .then((res) => {
        if(res.data.status == 200 && res.data.message == "sukses"){
          vm.$store.commit('set_alert', {variant: "success", msg: "BERHASIL MENGHAPUS SAMPEL", showing: true,})
          vm.getSampel()
        }
      })
      .catch((err) => {
        console.log(err);
      })
    },
  },
};
</script>

<style scoped>
.input-group-text{
  font-size: 12px;
}
.title-checkbox{
  font-weight: 600;
  color: #202020;
}
.card-columns {
  column-count: 4;
}

.card-columns .card-body{
  padding: 10px;
}

.title-16{
  font-weight: 700;
  color:#D52F65;
  font-size: 16px;
}
</style>
